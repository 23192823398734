import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { getUser, signIn as sendSignInRequest, renewToken } from "../api/auth";
import type { User, AuthContextType } from "../types";

function AuthProvider(props: React.PropsWithChildren<unknown>) {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }

      setInterval(renewToken, 60 * 60 * 1000);
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (email: string, password: string) => {
    const signInResult = await sendSignInRequest(email, password);
    if (signInResult.isOk) {
      const userResult = await getUser();
      if (userResult.isOk) {
        setUser(userResult.data);
      }
      return userResult;
    }
    return signInResult;
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, loading }}
      {...props}
    />
  );
}

const AuthContext = createContext<AuthContextType>({
  loading: false,
} as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
