import React from "react";
import { FileManager, Permissions } from "devextreme-react/file-manager";
import CustomFileSystemProvider from "devextreme/file_management/custom_provider";
import { getItems, downloadItems } from "../../api/files";
import "./file-manager.scss";

const fileSystemProvider = new CustomFileSystemProvider({
  getItems,
  downloadItems,
});

export default () => (
  <React.Fragment>
    <h2 className={"content-block"}>File Manager</h2>
    <div className={"content-block"}>
      <div className={"dx-card responsive-paddings"}>
        <FileManager fileSystemProvider={fileSystemProvider}>
          <Permissions download={true} />
        </FileManager>
      </div>
    </div>
  </React.Fragment>
);
