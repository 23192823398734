import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import appInfo from "./app-info";
import routes from "./app-routes";
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";
import { Footer } from "./components";
import { getInfo } from "./api/system";

export default function Content() {
  const [navTitle, setNavTitle] = useState(appInfo.title);

  useEffect(() => {
    (async function () {
      const info = await getInfo();
      let version = info.version || "";
      if (version) {
        if (info.environment !== "prod") {
          version += "beta";
        }
        version = " (v" + version + ")";
      }

      setNavTitle(info.title + version);
    })();
  }, []);

  return (
    <SideNavBarLayout title={navTitle}>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
      <Footer>
        Copyright © 2011-{new Date().getFullYear()} {appInfo.title} Inc.
        <br />
        All trademarks or registered trademarks are property of their respective
        owners.
      </Footer>
    </SideNavBarLayout>
  );
}
