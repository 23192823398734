import { default as defaultAppInfo } from "../app-info";

export async function getInfo() {
  try {
    const resp = await fetch("/api/system/version");
    return await resp.json();
  } catch {
    return defaultAppInfo;
  }
}
