import defaultUser from "../utils/default-user";

export async function signIn(email: string, password: string) {
  try {
    // Send request
    console.log(email, password);
    const resp = await fetch("/api/auth/login", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "username=" +
        encodeURIComponent(email) +
        "&password=" +
        encodeURIComponent(password),
    });
    const data = await resp.json();
    if (data.access_token) {
      window.localStorage.setItem("littletoken", data.access_token);
      return { isOk: true };
    }
    return {
      isOk: false,
      message: data.detail || "Sign-in failed",
    };
  } catch {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

export async function renewToken() {
  try {
    // Send request
    const resp = await fetch("/api/auth/login", {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("littletoken"),
      },
    });
    const data = await resp.json();
    if (data.access_token) {
      window.localStorage.setItem("littletoken", data.access_token);
      return { isOk: true };
    }
    return { isOk: false };
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function getUser() {
  try {
    // Send request
    const resp = await fetch("/api/auth/login", {
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("littletoken"),
      },
    });
    const data = await resp.json();
    if (data.email) {
      return { isOk: true, data: data };
    }
    return { isOk: false };
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(email: string, password: string) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to create account",
    };
  }
}

export async function changePassword(email: string, recoveryCode?: string) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to change password",
    };
  }
}

export async function resetPassword(email: string) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to reset password",
    };
  }
}
