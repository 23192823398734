import notify from "devextreme/ui/notify";
import FileSystemItem from "devextreme/file_management/file_system_item";

export async function getItems(
  parentDirectory: FileSystemItem
): Promise<FileSystemItem[]> {
  let params = "";
  if (parentDirectory.path) {
    params += "?parent_dir=" + parentDirectory.path;
  }
  try {
    const resp = await fetch("/api/file/list" + params, {
      headers: {
        Authorization: "Bearer " + window.localStorage.getItem("littletoken"),
      },
    });
    if (resp.status === 200) {
      return await resp.json();
    }
    notify(`Failed to getFiles [${resp.status}]`, "warning", 10000);
    return [];
  } catch {
    notify("Failed to getFiles", "error", 10000);
    return [];
  }
}

export function downloadItems(fileItems: FileSystemItem[]): void {
  const token = window.localStorage.getItem("littletoken");
  if (token) {
    for (let item of fileItems) {
      window.open("/api/file/" + token + "/" + item.path);
    }
  }
}
